import { CountUp } from 'countup.js';
import { isElementInViewport } from '../../../assets/scripts/common/utils';

document.addEventListener('DOMContentLoaded', function () {
  if (
    0 < document.querySelectorAll('.block-numbers__list__item__text').length
  ) {
    const counters = document.querySelectorAll('.numberCounter');

    counters.forEach((counter) => {
      const number = counter.innerHTML;

      const animation = new CountUp(counter, number);

      function counterAnimation() {
        if (
          isElementInViewport(
            document.querySelector('.block-numbers__list__item__text')
          )
        ) {
          animation.start();
        }
      }

      document.addEventListener('touchmove', counterAnimation, false);
      document.addEventListener('scroll', counterAnimation, false);
    });
  }
});
